.pre-nav {
    font-size: .8rem;
}

.navbar-nav {
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1rem 0;
        color: #000000;
        text-decoration: none;
        line-height: 1.2rem;
        font-weight: 400;

        @media screen and (min-width:1200px) {
            margin-right: 2rem;
        }

        &:hover, &:focus {
            text-decoration: underline;
            color: var(--ez-purple);
        }
    }
    
    a:nth-last-child(2) {
        position: relative;

        &:after {
            content: "";
            display: inline-block;
            margin-left: 5px;
            width: 25px;
            height: 25px;
            background-image: url('/media/ezpassva/site-assets/images/icon-pdf-colour.svg');
            background-repeat: no-repeat;
        }

        @media screen and (min-width:1200px) {
            font-size: .9rem;
            // margin-left: auto;
            margin-right: .8rem;
        }
    }

    a:last-child {
        background-color: var(--ez-purple);
        padding: 0.7rem 1.2rem;
        color: #fff;
        border-radius: 5px;
        width: fit-content;
        margin: 1rem auto;
        max-height: 40px;
        align-self: center;
        
        &:hover, &:focus {
            background-color: var(--ez-purple-dark);
            color: white;
        }

        @media screen and (min-width:1200px) {
            margin: unset;
            // padding: 0.5rem 1.2rem;
        }
    }

    #google_translate_element {
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (min-width:1200px) {
            margin: unset;
            margin-right: .5rem;
        }

        a {
            display: unset;
            background-color: unset;
            color: unset;
            padding: unset;
            border-radius: unset;

            &:hover, &:focus {
                text-decoration: unset;
            }
        }
    }
}

.navbar-brand {
    max-width: 45%;
    
    img {
        max-width: 190px;
    }

    @media screen and (min-width:768px) {
        max-width: 190px;
    }
}

.navbar-collapse {
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    @media screen and (min-width:1200px) {
        height: unset;
    }
}

.site-search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all ease-in-out .3s;
    border: 0;
    background-color: transparent;
    flex-shrink: 0;
    padding: 0;

    svg {
        fill: #3e3e3e;
        width: 30px;
        height: 30px;
        &:hover, &:focus {
            fill: #292929;
            transform: scale(.9);
        }
    }
}

.skip-to-main {
    position: absolute;
    top: 2rem;
    left:3rem;
    background-color: #fff;
    box-shadow: 4px 4px 4px 0px black;
    transform: translateX(-150%);
    z-index: 1100;
    padding: 1rem 1.5rem;
    opacity: 0;
    color: #000000;
    text-decoration: none;

    &:focus{
        transform: translateX(0);
        opacity: 1;
    }
}


// START FIXED NAVIGATION - confirm with client
.site-navigation {
    position: sticky;
    top: 0;
    z-index: 900;
    background: white;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.30);
    max-height: 100vh;
    overflow-y: scroll;

    @media screen and (min-width:768px) {
        overflow-y: unset;
    }
}

// body {
//     padding-top: 105px;

//     @media screen and (min-width:1200px) {
//         padding-top: 110px;
//     }
// }

html { 
    scroll-padding-top: 165px; 
}

#site-search-form {
    position: fixed !important;
    width: 100%;
    z-index: 1000;
}

// END FIXED NAVIGATION - confirm with client