.ct-slider {
    position: relative;

    .news-item[aria-hidden="true"] a {
        display: none !important;
    }

    #homepage-news-slider {
        display: flex;
        margin-bottom: 5px;
    }

    #homepage-news-slider-controls {
        display: flex;
        gap: 1.2rem;
        justify-content: end;
        align-items: center;
        margin-right: 0.7rem;
    }

    .slider-prev, .slider-next {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        width: 50px;
        height: 50px;
        background-color: grey;
        border-radius: 50%;
        cursor: pointer;
        transition: all ease-in-out .3s;

        &:hover, &:focus {
            background-color: var(--ez-purple-dark);
        }
    }
}