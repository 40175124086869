.alphabet {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0;
    justify-content: space-between;

    li {
        display: grid;
        place-items: center;
        width: 35px;
        height: 35px;
        background-color: #ffffff;
        outline: 1px solid var(--ez-purple);
        transition: all ease-in-out .1s;
        
        &:hover, &:focus {
            text-decoration: none;
            // background-color: var(--ez-purple-dark);
            // color: #ffffff;
            cursor: pointer;
            outline: 2px solid var(--ez-purple-dark);
        }
    }

    li.active {
        color: #ffffff;
        background-color: var(--ez-purple);
    }


}