body {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem; //equal to 16px
    line-height: 150%;
}

.container {
    max-width: 1142px;
}

.navbar > .container {
    max-width: 1320px;
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1.2rem;
    font-weight: 600;
}

p {
    margin-bottom: 2rem;
}

a {
    font-weight: 600;
    color: var(--ez-purple);
    text-decoration: none;
    
    &:hover, &:focus {
        text-decoration: underline;
        color: var(--ez-purple-dark);
    }
}

iframe[src*=youtube] {
    clip-path: inset(1px 1px);
}

.bg-eznavy {
    background-color: var(--ez-navy);
}

.bg-ezpurple {
    background-color: var(--ez-purple);
}

.bg-ezgrey {
    background-color: var(--ez-grey);
}

.bg-ezmint {
    background-color: var(--ez-mint);
}

.text-ezpurple {
    color: var(--ez-purple);
}

.border-grey {
    border-color: var(--ez-grey);
}

.border-purple {
    border-color: var(--ez-purple) !important;
}

.shadow-sm {
    box-shadow: 0 .25rem .25rem rgba(0,0,0,.25)!important;
}

.float-right {
    float: right;
    margin: 0 0 1rem 1rem;
    clear: right;
    width: 100%;

    @media screen and (min-width:768px) {
        max-width: 50%;
        width: unset;
    }

    @media screen and (min-width:1200px) {
        max-width: 100%;
        width: unset;
    }
}

.float-left {
    float: left;
    margin: 0 1rem 1rem 0;
    clear: left;
    width: 100%;

    @media screen and (min-width:768px) {
        max-width: 50%;
        width: unset;
    }

    @media screen and (min-width:1200px) {
        max-width: 100%;
        width: unset;
    }
}

.btn {
    box-shadow: 3px 3px 3px 0px rgba(0,0,0,.2);
    padding: .5rem 1rem;

    &:hover, &:focus {
        box-shadow: 3px 3px 3px 0px rgba(0,0,0,.4);
    }
}

.btn-ezpurple {
    background-color: var(--ez-purple);
    padding: .5rem 1rem;
    color: white;
    margin: 0 0.5rem 0.5rem 0;
    
    &:hover, &:focus {
        background-color: var(--ez-purple-dark);
        color: white;
    }
}

.btn-light {
    margin: 0 0.5rem 0.5rem 0;
}

.btn-outline-ezpurple {
    outline: 1px solid var(--ez-purple);
    padding: .5rem 1rem;
    color: var(--ez-purple);
    font-weight: 600;
    box-shadow: none;
    margin: 0 0.5rem 0.5rem 0;
    
    &:hover, &:focus {
        background-color: var(--ez-purple);
        color: white;
        text-decoration: none;
    }
}

// .btn-orange-hover {
//     &:hover, &:focus {
//         background-color: var(--ez-goldenrod);
//         border-color: var(--ez-goldenrod);
//     }
// }

// .offset-wrapper {
//     margin-bottom: 120px;
// }

// .offset-section {
//     top: 4rem;
// }

#main-content {
    padding: 3rem 0 3rem 0;
    background-color: #f8f9fa!important;
    min-height: 600px;

    @media screen and (min-width:768px) {
        background-image: url('/media/ezpassva/site-assets/images/bg-road.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center 350px;
    }
}

.homepage-action {
    transition: all ease-in-out .3s;
    border-bottom: 8px solid transparent;

    &:hover, &:focus {
        transform: translateY(-2%);
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.35) !important;
        border-bottom: 8px solid var(--ez-purple);
    }
}



.external-link {
    position: relative;

    &:after {
        content: "";
        display: block;
        width: 120px;
        height: 120px;
        background-color: var(--ez-purple);
        background-image: url('/media/ezpassva/site-assets/images/icon-external-link-white.svg');
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        right: 0;
        clip-path: polygon(100% 0, 0 0, 100% 100%);
        background-position-x: 80%;
        background-position-y: 20%;
    }
}

.external-text-link {
    position: relative;
    display: inline-block;

    &:after {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        vertical-align: middle;
        background-image: url('/media/ezpassva/site-assets/images/icon-external-link-purple.svg');
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        top: 0;
        right: -5px;
    }
}

.news-figure {
    img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    figcaption {
        padding: 10px 10px;
        background-color: rgb(228, 228, 228);
    }
}

.news-item {
    a {
        position: relative;
        display: block;
    }

    .photo-credit {
        position: absolute;
        top: 0px;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        font-size: .8rem;
        padding: 0 5px;
    }
}

#use-location-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff;
}

.reload-card-link {
    text-decoration: none;
    color: #212529;
    font-weight: normal;
    transition: all ease-in-out .3s;

    &:hover, &:focus {
        text-decoration: none;
        transform: scale(.98) translate(0px, 3px);
    }
}

.custom-blockquote {
    // border-left: 3px solid grey;
    background-color: #EDEDED;
    padding: 1.8rem 1rem 0.5rem 2rem;
    margin-bottom: 2rem;
}

#main-content ul, #main-content ol {
    margin-bottom: 2rem;
    li {
        margin-bottom: .5rem;
    }
}

input,select {
    padding: 15px;
    display: block;
    width: 100%;
    font-size: 1rem;
}

input[type="checkbox"] {
    position: relative;
    display: block;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    color: #000;
    border: 1px solid var(--ez-purple);
    background-color: #ffffff;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    margin-right: 10px;
    border-radius: 5px;

    &:focus, &:active {
        outline: 1px solid black;
    }
}

input[type="checkbox"]::before {
    position: absolute;
    content: "";
    display: block;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: #fff;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -63%) rotate(45deg);
    opacity: 0;
}

input[type="checkbox"]:checked {
    color: #ffffff;
    border-color: var(--ez-purple-dark);
    background: var(--ez-purple);
    border-radius: 4px;
}

input[type="checkbox"]:checked::before {
    opacity: 1;
}

.checkbox-group {
    display: grid; 
    align-items: center;
    grid-template-columns: 50px 1fr; 
    margin: 0 0 1rem 1rem;

    label {
        margin: 0;
        line-height: 1.2rem;
        &:hover{
            cursor: pointer;
        }
    }
}

.form-control:focus {
    border-color: transparent;
    box-shadow: none;
    outline: 2px solid #000000cc;
    outline-offset: 1px;
}

.table {
    margin-bottom: 3rem;
}

.container .container {
    padding: 0;
}

// @media screen and (min-width:992px) {

// }