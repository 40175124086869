.sidebar-link a {
    display: block;
    border-bottom: 1px solid var(--ez-grey);
    color: var(--ez-purple);
    padding: 10px 10px 10px 20px;
    position: relative;
    margin-bottom: 1rem;
    font-weight: normal;

    &:before {
        position: absolute;
        top: 10px;
        left: 0;
        content: ">"
    }
}