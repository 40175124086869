:root {
    --ez-purple: #804092;
    --ez-purple-dark: #4c2657;
    --ez-navy: #393472;
    --ez-grey: #E1E3E5;
    --ez-mint: #C8E8E8;
    --ez-medium-blue: #77A5E8;
    --ez-powder-blue: #B0CEEA;
    --ez-dark-blue: #5380B7;
    --ez-dark-green: #35576F;
    --ez-aqua: #01CBD0;
    --ez-teal: #0991C4;
    --ez-light-blue: #C5E0FF;
    --ez-goldenrod: #FDAD03;
    --ez-light-grey: #F4F7FA;
    --ez-light-purple: #A099CB;
    --ez-medium-grey: #C2C2C2;
    --ez-bonfire: #D3A098;
    --ez-vdot-orange: #f37735;
    }