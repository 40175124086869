#about-page-mobile-nav {

    .dropdown-toggle {
        background-color: #EDEDED;
        color: black;

        &:after {
            display: none;
        }
    }

    .nav-item {
        width: 100%;
    }

    .nav-link {
        display: block;
        padding: 0.6rem 1rem;
        border: none;
        text-align: left;
        color: black;
        background-color: #ffffff;
        border-radius: 5px;
        margin-bottom: 6px;
        width: 100%;
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.20);
    }

    .nav-link.active{
        background-color: var(--ez-purple);
        color: white;
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.3);
    }
    
    .nav-tabs {
        border: 0;
        padding: .5rem
    }
}

#about-page-desktop-tabs {

    flex-direction: column;
    flex-wrap: nowrap;
    position: sticky;
    top: 120px;
    border: none;

    ul li {
        margin-bottom: 10px;
    }

    li:last-of-type > .nav-link {
        margin-bottom: 0;
    }

    .nav-link {
        border: none;
        width: 100%;
        text-align: left;
        color: black;
        background-color: #ffffff;
        border-radius: 5px;
        margin-bottom: 6px;
        padding: 0.6rem 1rem;
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.20);
        transition: all ease-in-out .3s;

        &:hover, &:focus {
            background-color: #ececec;
        }
    }
    .nav-link.active{
        background-color: var(--ez-purple);
        color: white;
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.3);
    }


}

#custom-tabs-radios {
border-bottom: 1px solid grey;

    .nav-link {
        border: none;
        font-weight: 600;
        color: #000000;
        margin-bottom: 0;

        &:before {
            content: url('/media/ezpassva/site-assets/images/icon-radio.svg');
            position: relative;
            top:6px;
            margin-right: 8px;
        }
    }

    .nav-link.active {
        &:before {
            content: url('/media/ezpassva/site-assets/images/icon-radio-active.svg');
        }
    }
}