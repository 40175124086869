#map {
	height: 70vh;
}

#resultsWrapper {
	height: auto;
}

body.resultsLoaded #map {
	@media screen and (min-width:1200px) {
		height: 100%;
	}
}

.retail-location {
	position: relative;
	padding-left: 30px;
	display: block;

	&::before{
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		display: block;
		width: 26px;
		height: 26px;
		background-image: url("/media/ezpassva/site-assets/images/icon-retail.svg");
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}
}

.customer-service-center {
	position: relative;
	padding-left: 30px;
	display: block;

	&::before{
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		display: block;
		width: 26px;
		height: 26px;
		background-image: url("/media/ezpassva/site-assets/images/icon-customer-service.svg");
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}
}

#use-location-button {
	transition: all ease-in-out .3s;
	&:hover, &:focus {
		background-color: var(--ez-purple-dark);
	}
}

.gm-style .gm-style-iw {
	font-size: 1rem;
	font-weight: 400;
	max-width: 250px !important;
}

.external {

	&:after {
		content:"";
		display: inline-block;
		width: 20px;
		height: 20px;
		background-image: url("/media/ezpassva/site-assets/images/icon-external-link-purple.svg");
		background-repeat: no-repeat;
		background-size: contain;
		vertical-align: middle;
		vertical-align: sub;
		margin-left: 10px;
	}
}

fieldset {
	display: none;

	@media screen and (min-width:1200px) {
		display: block;
	}
}

.location-dropdown {
	background-size: 1rem;
    background-image: url("/media/ezpassva/site-assets/images/icon-plus-black.svg");
	background-repeat: no-repeat;
	background-position: 96% center;

	@media screen and (min-width:1200px) {
		background-image: none;
	}
}

.dd-active {
    background-image: url("/media/ezpassva/site-assets/images/icon-minus-black.svg");
	@media screen and (min-width:1200px) {
		background-image: none;
	}
}