.accordion-button {
    font-size: 1.1rem;
}

.accordion-button:not(.collapsed) {
    color: var(--ez-purple);
    font-weight: 600;
    background-color: rgb(236, 236, 236) !important;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
}

.accordion-button::after {
    width: 1rem;
    height: 1rem;
    background-size: 1rem;
    background-image: url("/media/ezpassva/site-assets/images/icon-plus-black.svg");
}

.accordion-button:not(.collapsed)::after {
    background-image: url("/media/ezpassva/site-assets/images/icon-minus-purple.svg");
    top: -6px;
    position: relative;
}

.accordion-body {
    padding: 2.5rem 1.25rem;
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 1px solid rgba(0,0,0,.125)
}