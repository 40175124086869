.text-search-input {
    
    input {
        border: none;
        background-color: #EEEEEE;
        color: #000000;
        border-radius: 0px !important;
        height: 60px;
    }
    
    button {
        box-shadow: none;
        border: none;
        border-radius: none;
        background-color: #EEEEEE;
        height: 60px;
        width: 60px;
        transition: all ease-in-out .3s;
        
        &:hover, &:focus, &:active {
            background-color: rgb(218, 218, 218);
            svg {
                fill: var(--ez-purple);
                box-shadow: none;
            }
        }
    }
}
.location-search-input {
    
    input {
        border: none;
        background-color: #EEEEEE;
        color: #000000;
        border-radius: 0px !important;
        height: 49px;
    }
    
    button {
        box-shadow: none;
        border: none;
        border-radius: none;
        background-color: #EEEEEE;
        height: 49px;
        width: 49px;
        transition: all ease-in-out .3s;
        
        &:hover, &:focus, &:active {
            background-color: rgb(218, 218, 218);
            svg {
                fill: var(--ez-purple);
                box-shadow: none;
            }
        }
    }
}