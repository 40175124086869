.dark-theme-content {
    *, a, font {
        color: white;
    }
    a.btn, a.btn font {
        color: #000000;
    }
}
.light-theme-content {
    *, a {
        color: #000000;
    }
    a.btn, a.btn font {
        color: #000000;
    }
}