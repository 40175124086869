@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
:root {
  --ez-purple: #804092;
  --ez-purple-dark: #4c2657;
  --ez-navy: #393472;
  --ez-grey: #E1E3E5;
  --ez-mint: #C8E8E8;
  --ez-medium-blue: #77A5E8;
  --ez-powder-blue: #B0CEEA;
  --ez-dark-blue: #5380B7;
  --ez-dark-green: #35576F;
  --ez-aqua: #01CBD0;
  --ez-teal: #0991C4;
  --ez-light-blue: #C5E0FF;
  --ez-goldenrod: #FDAD03;
  --ez-light-grey: #F4F7FA;
  --ez-light-purple: #A099CB;
  --ez-medium-grey: #C2C2C2;
  --ez-bonfire: #D3A098;
  --ez-vdot-orange: #f37735;
}

.pre-nav {
  font-size: 0.8rem;
}

.navbar-nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  color: #000000;
  text-decoration: none;
  line-height: 1.2rem;
  font-weight: 400;
}

@media screen and (min-width: 1200px) {
  .navbar-nav a {
    margin-right: 2rem;
  }
}
.navbar-nav a:hover, .navbar-nav a:focus {
  text-decoration: underline;
  color: var(--ez-purple);
}

.navbar-nav a:nth-last-child(2) {
  position: relative;
}

.navbar-nav a:nth-last-child(2):after {
  content: "";
  display: inline-block;
  margin-left: 5px;
  width: 25px;
  height: 25px;
  background-image: url("/media/ezpassva/site-assets/images/icon-pdf-colour.svg");
  background-repeat: no-repeat;
}

@media screen and (min-width: 1200px) {
  .navbar-nav a:nth-last-child(2) {
    font-size: 0.9rem;
    margin-right: 0.8rem;
  }
}
.navbar-nav a:last-child {
  background-color: var(--ez-purple);
  padding: 0.7rem 1.2rem;
  color: #fff;
  border-radius: 5px;
  width: fit-content;
  margin: 1rem auto;
  max-height: 40px;
  align-self: center;
}

.navbar-nav a:last-child:hover, .navbar-nav a:last-child:focus {
  background-color: var(--ez-purple-dark);
  color: white;
}

@media screen and (min-width: 1200px) {
  .navbar-nav a:last-child {
    margin: unset;
  }
}
.navbar-nav #google_translate_element {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1200px) {
  .navbar-nav #google_translate_element {
    margin: unset;
    margin-right: 0.5rem;
  }
}
.navbar-nav #google_translate_element a {
  display: unset;
  background-color: unset;
  color: unset;
  padding: unset;
  border-radius: unset;
}

.navbar-nav #google_translate_element a:hover, .navbar-nav #google_translate_element a:focus {
  text-decoration: unset;
}

.navbar-brand {
  max-width: 45%;
}

.navbar-brand img {
  max-width: 190px;
}

@media screen and (min-width: 768px) {
  .navbar-brand {
    max-width: 190px;
  }
}
.navbar-collapse {
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
}

@media screen and (min-width: 1200px) {
  .navbar-collapse {
    height: unset;
  }
}
.site-search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  border: 0;
  background-color: transparent;
  flex-shrink: 0;
  padding: 0;
}

.site-search-icon svg {
  fill: #3e3e3e;
  width: 30px;
  height: 30px;
}

.site-search-icon svg:hover, .site-search-icon svg:focus {
  fill: #292929;
  transform: scale(0.9);
}

.skip-to-main {
  position: absolute;
  top: 2rem;
  left: 3rem;
  background-color: #fff;
  box-shadow: 4px 4px 4px 0px black;
  transform: translateX(-150%);
  z-index: 1100;
  padding: 1rem 1.5rem;
  opacity: 0;
  color: #000000;
  text-decoration: none;
}

.skip-to-main:focus {
  transform: translateX(0);
  opacity: 1;
}

.site-navigation {
  position: sticky;
  top: 0;
  z-index: 900;
  background: white;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-height: 100vh;
  overflow-y: scroll;
}

@media screen and (min-width: 768px) {
  .site-navigation {
    overflow-y: unset;
  }
}
html {
  scroll-padding-top: 165px;
}

#site-search-form {
  position: fixed !important;
  width: 100%;
  z-index: 1000;
}

#about-page-mobile-nav .dropdown-toggle {
  background-color: #EDEDED;
  color: black;
}

#about-page-mobile-nav .dropdown-toggle:after {
  display: none;
}

#about-page-mobile-nav .nav-item {
  width: 100%;
}

#about-page-mobile-nav .nav-link {
  display: block;
  padding: 0.6rem 1rem;
  border: none;
  text-align: left;
  color: black;
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 6px;
  width: 100%;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
}

#about-page-mobile-nav .nav-link.active {
  background-color: var(--ez-purple);
  color: white;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.3);
}

#about-page-mobile-nav .nav-tabs {
  border: 0;
  padding: 0.5rem;
}

#about-page-desktop-tabs {
  flex-direction: column;
  flex-wrap: nowrap;
  position: sticky;
  top: 120px;
  border: none;
}

#about-page-desktop-tabs ul li {
  margin-bottom: 10px;
}

#about-page-desktop-tabs li:last-of-type > .nav-link {
  margin-bottom: 0;
}

#about-page-desktop-tabs .nav-link {
  border: none;
  width: 100%;
  text-align: left;
  color: black;
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 6px;
  padding: 0.6rem 1rem;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
  transition: all ease-in-out 0.3s;
}

#about-page-desktop-tabs .nav-link:hover, #about-page-desktop-tabs .nav-link:focus {
  background-color: #ececec;
}

#about-page-desktop-tabs .nav-link.active {
  background-color: var(--ez-purple);
  color: white;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.3);
}

#custom-tabs-radios {
  border-bottom: 1px solid grey;
}

#custom-tabs-radios .nav-link {
  border: none;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0;
}

#custom-tabs-radios .nav-link:before {
  content: url("/media/ezpassva/site-assets/images/icon-radio.svg");
  position: relative;
  top: 6px;
  margin-right: 8px;
}

#custom-tabs-radios .nav-link.active:before {
  content: url("/media/ezpassva/site-assets/images/icon-radio-active.svg");
}

.text-search-input input {
  border: none;
  background-color: #EEEEEE;
  color: #000000;
  border-radius: 0px !important;
  height: 60px;
}

.text-search-input button {
  box-shadow: none;
  border: none;
  border-radius: none;
  background-color: #EEEEEE;
  height: 60px;
  width: 60px;
  transition: all ease-in-out 0.3s;
}

.text-search-input button:hover, .text-search-input button:focus, .text-search-input button:active {
  background-color: rgb(218, 218, 218);
}

.text-search-input button:hover svg, .text-search-input button:focus svg, .text-search-input button:active svg {
  fill: var(--ez-purple);
  box-shadow: none;
}

.location-search-input input {
  border: none;
  background-color: #EEEEEE;
  color: #000000;
  border-radius: 0px !important;
  height: 49px;
}

.location-search-input button {
  box-shadow: none;
  border: none;
  border-radius: none;
  background-color: #EEEEEE;
  height: 49px;
  width: 49px;
  transition: all ease-in-out 0.3s;
}

.location-search-input button:hover, .location-search-input button:focus, .location-search-input button:active {
  background-color: rgb(218, 218, 218);
}

.location-search-input button:hover svg, .location-search-input button:focus svg, .location-search-input button:active svg {
  fill: var(--ez-purple);
  box-shadow: none;
}

.alphabet {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 0;
  justify-content: space-between;
}

.alphabet li {
  display: grid;
  place-items: center;
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  outline: 1px solid var(--ez-purple);
  transition: all ease-in-out 0.1s;
}

.alphabet li:hover, .alphabet li:focus {
  text-decoration: none;
  cursor: pointer;
  outline: 2px solid var(--ez-purple-dark);
}

.alphabet li.active {
  color: #ffffff;
  background-color: var(--ez-purple);
}

.sidebar-link a {
  display: block;
  border-bottom: 1px solid var(--ez-grey);
  color: var(--ez-purple);
  padding: 10px 10px 10px 20px;
  position: relative;
  margin-bottom: 1rem;
  font-weight: normal;
}

.sidebar-link a:before {
  position: absolute;
  top: 10px;
  left: 0;
  content: ">";
}

#site-search-form {
  position: absolute;
  top: -200%;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.4588235294);
  transition: all ease-in-out 0.3s;
}

#close-search-button {
  border: 0;
  background-color: transparent;
}

#emergency-alert {
  line-height: 1.15rem;
}

#emergency-alert a {
  white-space: nowrap;
}

.pagination a, .pagination span {
  position: relative;
  display: block;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;
}

.pagination a:focus, .pagination span:focus {
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.pagination li > span, .pagination li > span:hover, .pagination li > a:hover {
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.pagination li a {
  cursor: pointer;
}

.pagination li:first-child, .pagination li:last-child {
  display: flex;
}

.pagination li:not(:first-child) a, .pagination li:not(:first-child) span {
  margin-left: -1px;
}

.pagination li:first-child a:not(:first-child) {
  margin-left: -1px;
}

.footer {
  background-color: #000000;
  color: #ffffff;
  font-size: 0.9rem;
}

.footer a {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  font-weight: normal;
}

.footer a:hover, .footer a:focus {
  color: #ffffff;
  border-bottom: 1px dashed #ffffff;
  text-decoration: none;
}

.footer .footer-logo {
  display: block;
  border-bottom: none;
  text-decoration: none;
}

.footer .footer-logo:hover, .footer .footer-logo:focus {
  border-bottom: none;
  text-decoration: none;
}

.footer .footer-logo img {
  max-width: 300px;
}

.footer #back-to-top-button {
  display: flex;
  opacity: 0;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 100;
  background-color: rgba(78, 78, 78, 0.4);
  cursor: pointer;
  border-radius: 50%;
  outline: 1px solid transparent;
  transition: all ease-in-out 0.3s;
}

.footer #back-to-top-button svg {
  display: block;
  fill: #ffffff;
  width: 20px;
  height: 25px;
  padding: 0;
  margin: 0;
}

.footer #back-to-top-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
  outline: 1px solid #ffffff;
}

.footer #back-to-top-button.active {
  opacity: 1;
  pointer-events: unset;
}

.footer .live-edit-link {
  text-decoration: none;
  border-bottom: none;
}

.footer .live-edit-link:hover, .footer .live-edit-link:focus {
  border-bottom: none;
  text-decoration: none;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 150%;
}

.container {
  max-width: 1142px;
}

.navbar > .container {
  max-width: 1320px;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1.2rem;
  font-weight: 600;
}

p {
  margin-bottom: 2rem;
}

a {
  font-weight: 600;
  color: var(--ez-purple);
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: underline;
  color: var(--ez-purple-dark);
}

iframe[src*=youtube] {
  clip-path: inset(1px 1px);
}

.bg-eznavy {
  background-color: var(--ez-navy);
}

.bg-ezpurple {
  background-color: var(--ez-purple);
}

.bg-ezgrey {
  background-color: var(--ez-grey);
}

.bg-ezmint {
  background-color: var(--ez-mint);
}

.text-ezpurple {
  color: var(--ez-purple);
}

.border-grey {
  border-color: var(--ez-grey);
}

.border-purple {
  border-color: var(--ez-purple) !important;
}

.shadow-sm {
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25) !important;
}

.float-right {
  float: right;
  margin: 0 0 1rem 1rem;
  clear: right;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .float-right {
    max-width: 50%;
    width: unset;
  }
}
@media screen and (min-width: 1200px) {
  .float-right {
    max-width: 100%;
    width: unset;
  }
}
.float-left {
  float: left;
  margin: 0 1rem 1rem 0;
  clear: left;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .float-left {
    max-width: 50%;
    width: unset;
  }
}
@media screen and (min-width: 1200px) {
  .float-left {
    max-width: 100%;
    width: unset;
  }
}
.btn {
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.2);
  padding: 0.5rem 1rem;
}

.btn:hover, .btn:focus {
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.4);
}

.btn-ezpurple {
  background-color: var(--ez-purple);
  padding: 0.5rem 1rem;
  color: white;
  margin: 0 0.5rem 0.5rem 0;
}

.btn-ezpurple:hover, .btn-ezpurple:focus {
  background-color: var(--ez-purple-dark);
  color: white;
}

.btn-light {
  margin: 0 0.5rem 0.5rem 0;
}

.btn-outline-ezpurple {
  outline: 1px solid var(--ez-purple);
  padding: 0.5rem 1rem;
  color: var(--ez-purple);
  font-weight: 600;
  box-shadow: none;
  margin: 0 0.5rem 0.5rem 0;
}

.btn-outline-ezpurple:hover, .btn-outline-ezpurple:focus {
  background-color: var(--ez-purple);
  color: white;
  text-decoration: none;
}

#main-content {
  padding: 3rem 0 3rem 0;
  background-color: #f8f9fa !important;
  min-height: 600px;
}

@media screen and (min-width: 768px) {
  #main-content {
    background-image: url("/media/ezpassva/site-assets/images/bg-road.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center 350px;
  }
}
.homepage-action {
  transition: all ease-in-out 0.3s;
  border-bottom: 8px solid transparent;
}

.homepage-action:hover, .homepage-action:focus {
  transform: translateY(-2%);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35) !important;
  border-bottom: 8px solid var(--ez-purple);
}

.external-link {
  position: relative;
}

.external-link:after {
  content: "";
  display: block;
  width: 120px;
  height: 120px;
  background-color: var(--ez-purple);
  background-image: url("/media/ezpassva/site-assets/images/icon-external-link-white.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  background-position-x: 80%;
  background-position-y: 20%;
}

.external-text-link {
  position: relative;
  display: inline-block;
}

.external-text-link:after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: middle;
  background-image: url("/media/ezpassva/site-assets/images/icon-external-link-purple.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  top: 0;
  right: -5px;
}

.news-figure img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.news-figure figcaption {
  padding: 10px 10px;
  background-color: rgb(228, 228, 228);
}

.news-item a {
  position: relative;
  display: block;
}

.news-item .photo-credit {
  position: absolute;
  top: 0px;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 0.8rem;
  padding: 0 5px;
}

#use-location-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
}

.reload-card-link {
  text-decoration: none;
  color: #212529;
  font-weight: normal;
  transition: all ease-in-out 0.3s;
}

.reload-card-link:hover, .reload-card-link:focus {
  text-decoration: none;
  transform: scale(0.98) translate(0px, 3px);
}

.custom-blockquote {
  background-color: #EDEDED;
  padding: 1.8rem 1rem 0.5rem 2rem;
  margin-bottom: 2rem;
}

#main-content ul, #main-content ol {
  margin-bottom: 2rem;
}

#main-content ul li, #main-content ol li {
  margin-bottom: 0.5rem;
}

input, select {
  padding: 15px;
  display: block;
  width: 100%;
  font-size: 1rem;
}

input[type=checkbox] {
  position: relative;
  display: block;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  color: #000;
  border: 1px solid var(--ez-purple);
  background-color: #ffffff;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  margin-right: 10px;
  border-radius: 5px;
}

input[type=checkbox]:focus, input[type=checkbox]:active {
  outline: 1px solid black;
}

input[type=checkbox]::before {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 14px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -63%) rotate(45deg);
  opacity: 0;
}

input[type=checkbox]:checked {
  color: #ffffff;
  border-color: var(--ez-purple-dark);
  background: var(--ez-purple);
  border-radius: 4px;
}

input[type=checkbox]:checked::before {
  opacity: 1;
}

.checkbox-group {
  display: grid;
  align-items: center;
  grid-template-columns: 50px 1fr;
  margin: 0 0 1rem 1rem;
}

.checkbox-group label {
  margin: 0;
  line-height: 1.2rem;
}

.checkbox-group label:hover {
  cursor: pointer;
}

.form-control:focus {
  border-color: transparent;
  box-shadow: none;
  outline: 2px solid rgba(0, 0, 0, 0.8);
  outline-offset: 1px;
}

.table {
  margin-bottom: 3rem;
}

.container .container {
  padding: 0;
}

.ct-video-block iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}

.accordion-button {
  font-size: 1.1rem;
}

.accordion-button:not(.collapsed) {
  color: var(--ez-purple);
  font-weight: 600;
  background-color: rgb(236, 236, 236) !important;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button::after {
  width: 1rem;
  height: 1rem;
  background-size: 1rem;
  background-image: url("/media/ezpassva/site-assets/images/icon-plus-black.svg");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("/media/ezpassva/site-assets/images/icon-minus-purple.svg");
  top: -6px;
  position: relative;
}

.accordion-body {
  padding: 2.5rem 1.25rem;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.ct-slider {
  position: relative;
}

.ct-slider .news-item[aria-hidden=true] a {
  display: none !important;
}

.ct-slider #homepage-news-slider {
  display: flex;
  margin-bottom: 5px;
}

.ct-slider #homepage-news-slider-controls {
  display: flex;
  gap: 1.2rem;
  justify-content: end;
  align-items: center;
  margin-right: 0.7rem;
}

.ct-slider .slider-prev, .ct-slider .slider-next {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  width: 50px;
  height: 50px;
  background-color: grey;
  border-radius: 50%;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}

.ct-slider .slider-prev:hover, .ct-slider .slider-prev:focus, .ct-slider .slider-next:hover, .ct-slider .slider-next:focus {
  background-color: var(--ez-purple-dark);
}

.ct-news-block a h2 {
  color: black;
}

#map {
  height: 70vh;
}

#resultsWrapper {
  height: auto;
}

@media screen and (min-width: 1200px) {
  body.resultsLoaded #map {
    height: 100%;
  }
}
.retail-location {
  position: relative;
  padding-left: 30px;
  display: block;
}

.retail-location::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 26px;
  height: 26px;
  background-image: url("/media/ezpassva/site-assets/images/icon-retail.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.customer-service-center {
  position: relative;
  padding-left: 30px;
  display: block;
}

.customer-service-center::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 26px;
  height: 26px;
  background-image: url("/media/ezpassva/site-assets/images/icon-customer-service.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

#use-location-button {
  transition: all ease-in-out 0.3s;
}

#use-location-button:hover, #use-location-button:focus {
  background-color: var(--ez-purple-dark);
}

.gm-style .gm-style-iw {
  font-size: 1rem;
  font-weight: 400;
  max-width: 250px !important;
}

.external:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("/media/ezpassva/site-assets/images/icon-external-link-purple.svg");
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  vertical-align: sub;
  margin-left: 10px;
}

fieldset {
  display: none;
}

@media screen and (min-width: 1200px) {
  fieldset {
    display: block;
  }
}
.location-dropdown {
  background-size: 1rem;
  background-image: url("/media/ezpassva/site-assets/images/icon-plus-black.svg");
  background-repeat: no-repeat;
  background-position: 96% center;
}

@media screen and (min-width: 1200px) {
  .location-dropdown {
    background-image: none;
  }
}
.dd-active {
  background-image: url("/media/ezpassva/site-assets/images/icon-minus-black.svg");
}

@media screen and (min-width: 1200px) {
  .dd-active {
    background-image: none;
  }
}
.dark-theme-content *, .dark-theme-content a, .dark-theme-content font {
  color: white;
}

.dark-theme-content a.btn, .dark-theme-content a.btn font {
  color: #000000;
}

.light-theme-content *, .light-theme-content a {
  color: #000000;
}

.light-theme-content a.btn, .light-theme-content a.btn font {
  color: #000000;
}