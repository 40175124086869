// .footer {
//     border-top: 10px solid var(--ez-vdot-orange);
//     font-size: .9rem;

//     #back-to-top-button {
//         display: flex;
//         opacity: 0;
//         pointer-events: none;
//         align-items: center;
//         justify-content: center;

//         width: 45px;
//         height: 45px;
//         position: fixed; 
//         bottom: 20px; 
//         right: 30px; 
//         z-index: 100; 
//         background-color: rgba(0, 0, 0, 0.4); 
//         cursor: pointer; 
//         border-radius: 50%; 
//         transition: all ease-in-out .3s;

//         svg {
//             display: block;
//             fill: #ffffff;
//             width: 20px;
//             height: 25px;
//             padding: 0;
//             margin: 0;
//         }
//     }

//     #back-to-top-button:hover {
//         background-color: rgba(0, 0, 0, 0.9); 
//     }

//     #back-to-top-button.active {
//         opacity: 1;
//         pointer-events: unset;
//     }
// }

.footer {
    background-color: #000000;
    color: #ffffff;
    font-size: .9rem;

    a {
        color: #ffffff;
        border-bottom: 1px solid #ffffff;
        font-weight: normal;

        &:hover, &:focus {
            color: #ffffff;
            border-bottom: 1px dashed #ffffff;
            text-decoration: none;
        }
    }

    .footer-logo {
        display: block;
        border-bottom: none;
        text-decoration: none;

        &:hover, &:focus {
            border-bottom: none;
            text-decoration: none;
        }

        img {
            max-width: 300px;
        }
    }

    #back-to-top-button {
        display: flex;
        opacity: 0;
        pointer-events: none;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        position: fixed; 
        bottom: 20px; 
        right: 30px; 
        z-index: 100; 
        background-color: rgba(78, 78, 78, 0.4); 
        cursor: pointer; 
        border-radius: 50%; 
        outline: 1px solid transparent;
        transition: all ease-in-out .3s;

        svg {
            display: block;
            fill: #ffffff;
            width: 20px;
            height: 25px;
            padding: 0;
            margin: 0;
        }
    }

    #back-to-top-button:hover {
        background-color: rgba(0, 0, 0, 0.9); 
        outline: 1px solid #ffffff;
    }

    #back-to-top-button.active {
        opacity: 1;
        pointer-events: unset;
    }

    .live-edit-link {
        text-decoration: none;
        border-bottom: none;
        
        &:hover, &:focus {
            border-bottom: none;
            text-decoration: none;
        }
    }
}