#site-search-form {
    position: absolute;
    top: -200%;
    width: 100%;
    background-color: white;
    box-shadow: 0 0 7px 2px #00000075;
    transition: all ease-in-out .3s;
}

#close-search-button {
    border: 0;
    background-color: transparent;
}