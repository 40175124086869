.pagination {
    
    a, span {
        position: relative;
        display: block;
        text-decoration: none;
        background-color: #fff;
        border: 1px solid #dee2e6;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        padding: 0.375rem 0.75rem;

        &:focus {
            background-color: #e9ecef;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
        }
    }

    li {

        > span, > span:hover, > a:hover {
            background-color: #e9ecef;
            border-color: #dee2e6;
        }

        a {
            cursor: pointer;
        }

        // acccount for T4 not treating next/last etc links as separate list items
        &:first-child, &:last-child {
            display: flex;
        }

        &:not(:first-child) {
            a, span {
               margin-left: -1px;
            }
        }

        &:first-child a:not(:first-child) {
            margin-left: -1px;
        }
    }
}